//
//
//
//
//
//
//

export default {
  name: "OrderCard",
  props: {
    title: {
      type: [String, Number],
      default: null
    }
  }
};