//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import checkCircle from "@/assets/check-circle-primary.png";
import SpinnerLoader from "@bit/joshk.vue-spinners-css.spinner-loader";
export default {
  name: "UploadingDialog",
  data: () => ({
    checkCircle,
    urlUpload: "https://docs.debio.network/complete-guidelines/user-guideline/upload-and-encrypt-data",
    urlDownload: "https://docs.debio.network/complete-guidelines/genetic-analyst-guideline/download-and-decrypt-data"
  }),
  components: {
    SpinnerLoader
  },
  props: {
    show: Boolean,
    type: String,
    url: String
  },
  computed: {
    renderTitle() {
      return this.type === "download" ? "Downloading" : "Uploading";
    },
    renderUrlDownload() {
      return this.type === "download" ? this.urlDownload : this.urlUpload;
    }
  }
};