import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/esnext.typed-array.find-last.js";
import "core-js/modules/esnext.typed-array.find-last-index.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CryptoJS from "crypto-js";
import Kilt from "@kiltprotocol/sdk-js";
import cryptWorker from "@/common/lib/ipfs/crypt-worker";
import { u8aToHex } from "@polkadot/util";
import { chevronLeftIcon, timerIcon, alertIcon } from "@debionetwork/ui-icons";
import { validateForms } from "@/common/lib/validate";
import {
// processGeneticAnalysis, TODO: Please use polkadot instead of hardcode
// rejectGeneticAnalysis, TODO: Please use polkadot instead of hardcode
// submitGeneticAnalysis, TODO: Please use polkadot instead of hardcode
queryGeneticAnalysisByGeneticAnalysisTrackingId, queryGeneticDataById, queryGeneticAnalystByAccountId, rejectGeneticAnalysisFee, submitGeneticAnalysisFee, queryGeneticAnalystServicesByHashId } from "@debionetwork/polkadot-provider";
import { mapState } from "vuex";
import { generalDebounce } from "@/common/lib/utils";
import { uploadFile, getFileUrl, downloadFile, decryptFile, downloadDocumentFile, getIpfsMetaData } from "@/common/lib/pinata-proxy";
import rulesHandler from "@/common/constants/rules";
import Card from "./Card.vue";
import UploadingDialog from "@/common/components/Dialog/UploadingDialog";
import { fromEther, formatUSDTE } from "@/common/lib/balance-format";
export default {
  name: "GAOrderDetails",
  mixins: [validateForms],
  components: {
    Card,
    UploadingDialog
  },
  data: () => ({
    chevronLeftIcon,
    timerIcon,
    alertIcon,
    step: 1,
    readMore: false,
    showTooltip: false,
    isLoading: false,
    downloading: false,
    isUploading: false,
    showModalReject: false,
    orderAccepted: false,
    orderRejected: false,
    messageError: null,
    publicKey: null,
    secretKey: null,
    rejectionTitle: null,
    rejectionDesc: null,
    txWeight: null,
    documentLinks: [],
    orderDataDetails: {
      analysis_info: {},
      document: {},
      analyst_info: {},
      totalChunks: 0,
      // Initialize totalChunks
      currentChunkIndex: 0,
      // Initialize currentChunkIndex
      isFailed: false,
      service_info: {}
    },
    hilightDescription: "",
    document: {
      file: null,
      recordLink: null,
      description: null
    },
    steppers: [{
      number: 1,
      title: "Confirm",
      active: false
    }, {
      number: 2,
      title: "Upload Result",
      active: false
    }, {
      number: 3,
      title: "Success",
      active: false
    }]
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      web3: state => state.web3Store.web3,
      mnemonicData: state => state.substrate.mnemonicData,
      lastEventData: state => state.substrate.lastEventData,
      wallet: state => state.substrate.wallet
    }),
    rejectedOrder() {
      var _this$orderDataDetail, _this$orderDataDetail2;
      return ((_this$orderDataDetail = this.orderDataDetails) === null || _this$orderDataDetail === void 0 ? void 0 : (_this$orderDataDetail2 = _this$orderDataDetail.analysis_info) === null || _this$orderDataDetail2 === void 0 ? void 0 : _this$orderDataDetail2.status) === "Rejected";
    },
    completed() {
      var _this$orderDataDetail3, _this$orderDataDetail4;
      return ((_this$orderDataDetail3 = this.orderDataDetails) === null || _this$orderDataDetail3 === void 0 ? void 0 : (_this$orderDataDetail4 = _this$orderDataDetail3.analysis_info) === null || _this$orderDataDetail4 === void 0 ? void 0 : _this$orderDataDetail4.status) === "ResultReady";
    },
    computeDisabledRejection() {
      var _this$orderDataDetail5, _this$orderDataDetail6;
      return this.orderAccepted || ((_this$orderDataDetail5 = this.orderDataDetails) === null || _this$orderDataDetail5 === void 0 ? void 0 : (_this$orderDataDetail6 = _this$orderDataDetail5.analysis_info) === null || _this$orderDataDetail6 === void 0 ? void 0 : _this$orderDataDetail6.status) === "InProgress" || this.completed || this.orderRejected;
    },
    computeButtonText() {
      return this.orderDataDetails.analysis_info.status === "InProgress" || this.orderAccepted ? "Upload" : "ACCEPT";
    },
    computeAvatar() {
      return this.orderDataDetails.analyst_info.profileImage ? this.orderDataDetails.analyst_info.profileImage : require("@/assets/defaultAvatar.svg");
    },
    computeStepper() {
      return this.steppers.map(stepper => {
        if (stepper.number === 1) return {
          ...stepper,
          active: stepper.number === this.step
        };
        return {
          ...stepper,
          active: stepper.number === this.step
        };
      });
    },
    computeServiceDesc() {
      var _this$orderDataDetail7, _this$orderDataDetail8, _this$orderDataDetail9, _this$orderDataDetail10, _this$orderDataDetail11, _this$orderDataDetail12, _this$orderDataDetail13, _this$orderDataDetail14, _this$orderDataDetail15;
      const formatedDesc = ((_this$orderDataDetail7 = this.orderDataDetails) === null || _this$orderDataDetail7 === void 0 ? void 0 : (_this$orderDataDetail8 = _this$orderDataDetail7.service_info) === null || _this$orderDataDetail8 === void 0 ? void 0 : (_this$orderDataDetail9 = _this$orderDataDetail8.description) === null || _this$orderDataDetail9 === void 0 ? void 0 : _this$orderDataDetail9.length) >= 140 ? "".concat((_this$orderDataDetail10 = this.orderDataDetails) === null || _this$orderDataDetail10 === void 0 ? void 0 : (_this$orderDataDetail11 = _this$orderDataDetail10.service_info) === null || _this$orderDataDetail11 === void 0 ? void 0 : _this$orderDataDetail11.description.slice(0, 140), "...") : (_this$orderDataDetail12 = this.orderDataDetails) === null || _this$orderDataDetail12 === void 0 ? void 0 : (_this$orderDataDetail13 = _this$orderDataDetail12.service_info) === null || _this$orderDataDetail13 === void 0 ? void 0 : _this$orderDataDetail13.description;
      return (_this$orderDataDetail14 = this.orderDataDetails) !== null && _this$orderDataDetail14 !== void 0 && (_this$orderDataDetail15 = _this$orderDataDetail14.service_info) !== null && _this$orderDataDetail15 !== void 0 && _this$orderDataDetail15.description ? formatedDesc : "No Description";
    },
    computeDetailsTitle() {
      var _this$orderDataDetail16, _this$orderDataDetail17, _this$orderDataDetail18, _this$orderDataDetail19, _this$orderDataDetail20;
      const sectionTitles = ["Upload Result", "Completed"];
      const GENETIC_STATUS = {
        REGISTERED: "Open",
        INPROGRESS: "In Progress",
        REJECTED: "Rejected",
        RESULTREADY: "Done"
      };
      const getGeneticStatus = GENETIC_STATUS[(_this$orderDataDetail16 = this.orderDataDetails) === null || _this$orderDataDetail16 === void 0 ? void 0 : (_this$orderDataDetail17 = _this$orderDataDetail16.analysis_info) === null || _this$orderDataDetail17 === void 0 ? void 0 : (_this$orderDataDetail18 = _this$orderDataDetail17.status) === null || _this$orderDataDetail18 === void 0 ? void 0 : _this$orderDataDetail18.toUpperCase()];
      if (!getGeneticStatus) return "Loading please wait...";else if (this.step === 1) return ((_this$orderDataDetail19 = this.orderDataDetails) === null || _this$orderDataDetail19 === void 0 ? void 0 : (_this$orderDataDetail20 = _this$orderDataDetail19.analysis_info) === null || _this$orderDataDetail20 === void 0 ? void 0 : _this$orderDataDetail20.status) === "Registered" ? "Awaiting Order" : "".concat(getGeneticStatus, " Order");else return sectionTitles[this.step - 2];
    }
  },
  watch: {
    mnemonicData(val) {
      if (val) this.initialDataKey();
    },
    $route: {
      deep: true,
      immediate: true,
      handler: async function (val) {
        await this.prepareData(val.params.id);
      }
    },
    lastEventData: {
      deep: true,
      immediate: true,
      handler: generalDebounce(async function (val) {
        if ((val === null || val === void 0 ? void 0 : val.section) === "geneticAnalysisOrders" || (val === null || val === void 0 ? void 0 : val.section) === "geneticAnalysis") await this.prepareData(this.$route.params.id);
        if ((val === null || val === void 0 ? void 0 : val.method) === "GeneticAnalysisRejected" || (val === null || val === void 0 ? void 0 : val.method) === "GeneticAnalysisOrderRefunded") {
          this.isLoading = false;
          this.handleHideModalReject();
          this.orderRejected = true;
        }
        if ((val === null || val === void 0 ? void 0 : val.method) === "GeneticAnalysisResultReady" || (val === null || val === void 0 ? void 0 : val.method) === "GeneticAnalysisOrderFulfilled") {
          this.isUploading = false;
          this.step = 3;
        }
      }, 100)
    },
    document: {
      deep: true,
      immediate: true,
      handler: generalDebounce(async function () {
        await this.calculateDocumentFee();
      }, 500)
    }
  },
  async created() {
    if (this.mnemonicData) this.initialDataKey();
    await this.prepareData(this.$route.params.id);
  },
  rules: {
    rejectionTitle: [rulesHandler.FIELD_REQUIRED, rulesHandler.ENGLISH_ALPHABET, rulesHandler.MAX_CHARACTER(50)],
    rejectionDesc: [rulesHandler.FIELD_REQUIRED, rulesHandler.ENGLISH_ALPHABET, rulesHandler.MAX_CHARACTER(255)],
    document: {
      file: [rulesHandler.FIELD_REQUIRED, rulesHandler.DEFAULT_ACCEPT_DOCUMENTS, rulesHandler.FILE_SIZE(220200960)],
      description: [rulesHandler.ENGLISH_ALPHABET, rulesHandler.MAX_CHARACTER(255)]
    }
  },
  methods: {
    initialDataKey() {
      const cred = Kilt.Identity.buildFromMnemonic(this.mnemonicData.toString(CryptoJS.enc.Utf8));
      this.publicKey = u8aToHex(cred.boxKeyPair.publicKey);
      this.secretKey = u8aToHex(cred.boxKeyPair.secretKey);
    },
    async prepareData(id) {
      try {
        var _analystReportDocumen, _analystReportDocumen2, _analystReportDocumen3, _analystReportDocumen4, _analystReportDocumen5, _analystReportDocumen6, _geneticLinkName$rows, _geneticLinkName$rows2, _geneticLinkName$rows3, _geneticLinkName$rows4, _geneticLinkName$rows5, _geneticLinkName$rows6, _this$orderDataDetail21, _this$orderDataDetail22, _this$orderDataDetail23, _this$orderDataDetail24;
        const data = (await this.api.query.geneticAnalysisOrders.geneticAnalysisOrders(id)).toHuman();

        // Prevent continue requests if the order doesn't exist on the data records
        if (!data) {
          this.messageError = "Oh no! We can't find your selected order. Please select another one";
          return;
        }
        const serviceData = await queryGeneticAnalystServicesByHashId(this.api, data.serviceId);
        const analystData = await queryGeneticAnalystByAccountId(this.api, data.sellerId);
        const analysisData = await queryGeneticAnalysisByGeneticAnalysisTrackingId(this.api, data.geneticAnalysisTrackingId);
        const geneticData = await queryGeneticDataById(this.api, data.geneticDataId);
        const geneticLinkName = await getIpfsMetaData(JSON.parse(data.geneticLink)[0].split("/").pop());
        const analystReportDocument = await getIpfsMetaData(analysisData.reportLink.split("/").pop());
        this.orderDataDetails = {
          ...data,
          analysis_info: {
            ...analysisData,
            fileName: ((_analystReportDocumen = analystReportDocument.rows[0]) === null || _analystReportDocumen === void 0 ? void 0 : (_analystReportDocumen2 = _analystReportDocumen.metadata) === null || _analystReportDocumen2 === void 0 ? void 0 : _analystReportDocumen2.name) || analysisData.reportLink.split("/").pop(),
            fileSize: this.formatBytes(((_analystReportDocumen3 = analystReportDocument.rows[0]) === null || _analystReportDocumen3 === void 0 ? void 0 : (_analystReportDocumen4 = _analystReportDocumen3.metadata) === null || _analystReportDocumen4 === void 0 ? void 0 : (_analystReportDocumen5 = _analystReportDocumen4.keyvalues) === null || _analystReportDocumen5 === void 0 ? void 0 : _analystReportDocumen5.fileSize) || (analystReportDocument === null || analystReportDocument === void 0 ? void 0 : (_analystReportDocumen6 = analystReportDocument.rows[0]) === null || _analystReportDocumen6 === void 0 ? void 0 : _analystReportDocumen6.size) || 0)
          },
          document: {
            ...geneticData,
            reportLink: data.geneticLink,
            fileName: ((_geneticLinkName$rows = geneticLinkName.rows[0]) === null || _geneticLinkName$rows === void 0 ? void 0 : (_geneticLinkName$rows2 = _geneticLinkName$rows.metadata) === null || _geneticLinkName$rows2 === void 0 ? void 0 : _geneticLinkName$rows2.name) || data.geneticLink.split("/").pop(),
            fileSize: this.formatBytes(((_geneticLinkName$rows3 = geneticLinkName.rows[0]) === null || _geneticLinkName$rows3 === void 0 ? void 0 : (_geneticLinkName$rows4 = _geneticLinkName$rows3.metadata) === null || _geneticLinkName$rows4 === void 0 ? void 0 : (_geneticLinkName$rows5 = _geneticLinkName$rows4.keyvalues) === null || _geneticLinkName$rows5 === void 0 ? void 0 : _geneticLinkName$rows5.fileSize) || (geneticLinkName === null || geneticLinkName === void 0 ? void 0 : (_geneticLinkName$rows6 = geneticLinkName.rows[0]) === null || _geneticLinkName$rows6 === void 0 ? void 0 : _geneticLinkName$rows6.size) || 0)
          },
          createdAt: new Date(+data.createdAt.replaceAll(",", "")).toLocaleString("en-GB", {
            day: "numeric",
            year: "numeric",
            month: "short"
          }),
          analyst_info: {
            ...analystData.info,
            id: analystData.accountId,
            name: "".concat(analystData.info.firstName, " ").concat(analystData.info.lastName)
          },
          service_info: {
            ...serviceData,
            ...serviceData.info,
            price: "\n              ".concat(fromEther(serviceData.info.pricesByCurrency[0].totalPrice, serviceData.info.pricesByCurrency[0].currency), "\n              ").concat(formatUSDTE(serviceData.info.pricesByCurrency[0].currency), "\n            "),
            expectedDuration: "".concat(serviceData.info.expectedDuration.duration, " ").concat(serviceData.info.expectedDuration.durationType)
          }
        };
        if (((_this$orderDataDetail21 = this.orderDataDetails) === null || _this$orderDataDetail21 === void 0 ? void 0 : (_this$orderDataDetail22 = _this$orderDataDetail21.analysis_info) === null || _this$orderDataDetail22 === void 0 ? void 0 : _this$orderDataDetail22.status) === "Rejected") this.hilightDescription = this.orderDataDetails.analysis_info.rejectedDescription || "-";
        if (((_this$orderDataDetail23 = this.orderDataDetails) === null || _this$orderDataDetail23 === void 0 ? void 0 : (_this$orderDataDetail24 = _this$orderDataDetail23.analysis_info) === null || _this$orderDataDetail24 === void 0 ? void 0 : _this$orderDataDetail24.status) === "InProgress") {
          const txWeight = await submitGeneticAnalysisFee(this.api, this.wallet, this.orderDataDetails.geneticAnalysisTrackingId, this.document.recordLink, this.document.description);
          this.txWeight = "Calculating...";
          this.txWeight = "".concat(Number(this.web3.utils.fromWei(String(txWeight.partialFee), "ether")).toFixed(4), " DBIO");
          this.step = 2;
          this.scrollToFormsSection();
          this.orderAccepted = true;
        }
        if (this.completed) {
          var _this$orderDataDetail25, _this$orderDataDetail26;
          this.hilightDescription = (_this$orderDataDetail25 = this.orderDataDetails) === null || _this$orderDataDetail25 === void 0 ? void 0 : (_this$orderDataDetail26 = _this$orderDataDetail25.analysis_info) === null || _this$orderDataDetail26 === void 0 ? void 0 : _this$orderDataDetail26.comment;
          this.step = 3;
        }
      } catch (e) {
        console.error(e);
        this.messageError = "Something went wrong. Please try again later";
      }
    },
    scrollToFormsSection() {
      const observer = new MutationObserver(mutations => {
        const formFinded = mutations.find(m => {
          var _m$addedNodes$, _m$addedNodes$$classL, _m$addedNodes$2, _m$addedNodes$2$class;
          return ((_m$addedNodes$ = m.addedNodes[0]) === null || _m$addedNodes$ === void 0 ? void 0 : (_m$addedNodes$$classL = _m$addedNodes$.classList) === null || _m$addedNodes$$classL === void 0 ? void 0 : _m$addedNodes$$classL.contains("upload-section")) && ((_m$addedNodes$2 = m.addedNodes[0]) === null || _m$addedNodes$2 === void 0 ? void 0 : (_m$addedNodes$2$class = _m$addedNodes$2.classList) === null || _m$addedNodes$2$class === void 0 ? void 0 : _m$addedNodes$2$class.contains("mt-6"));
        });
        if (formFinded) {
          // Wait until the form upload showed up
          document.querySelector(".upload-section__forms").scrollIntoView({
            behavior: "smooth"
          });
          observer.disconnect();
        }
      });
      observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: false,
        characterData: false
      });
    },
    handlePrevious() {
      if (this.step === 1 || this.step === 3 && this.completed) {
        this.$router.go(-1);
        return;
      } else {
        this.step--;
      }
    },
    async handleAcceptOrder() {
      var _this$orderDataDetail27;
      if (((_this$orderDataDetail27 = this.orderDataDetails.analysis_info) === null || _this$orderDataDetail27 === void 0 ? void 0 : _this$orderDataDetail27.status) === "InProgress" || this.orderAccepted) {
        this.step = 2;
        this.scrollToFormsSection();
        return;
      }
      try {
        this.isLoading = true;
        // TODO: Please use polkadot instead of hardcode
        await this.api.tx.geneticAnalysis.processGeneticAnalysis(this.orderDataDetails.geneticAnalysisTrackingId, "InProgress").signAndSend(this.wallet, {
          nonce: -1
        });
        // await processGeneticAnalysis(this.api, this.wallet, this.orderDataDetails.geneticAnalysisTrackingId, "InProgress")
        await this.calculateDocumentFee();
        this.orderAccepted = true;
        this.step = 2;
        this.scrollToFormsSection();
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async handleShowModalReject() {
      await this.calculateRejectFee();
      this.showModalReject = true;
    },
    async handleHideModalReject() {
      this.showModalReject = false;
      this.txWeight = null;
    },
    async handleSubmitRejection() {
      try {
        this.isLoading = true;
        // TODO: Please use polkadot instead of hardcode
        await this.api.tx.geneticAnalysis.rejectGeneticAnalysis(this.orderDataDetails.geneticAnalysisTrackingId, this.rejectionTitle, this.rejectionDesc).signAndSend(this.wallet, {
          nonce: -1
        });
        // await rejectGeneticAnalysis(
        //   this.api,
        //   this.wallet,
        //   this.orderDataDetails.geneticAnalysisTrackingId,
        //   this.rejectionTitle,
        //   this.rejectionDesc
        // )
      } catch (e) {
        this.isLoading = false;
        this.orderRejected = false;
        console.error(e);
      } finally {
        this.txWeight = null;
      }
    },
    async handleRejectionTitle() {
      await this.calculateRejectFee();
    },
    async handleRejectionDesc() {
      await this.calculateRejectFee();
    },
    async calculateRejectFee() {
      const txWeight = await rejectGeneticAnalysisFee(this.api, this.wallet, this.orderDataDetails.geneticAnalysisTrackingId, this.rejectionTitle, this.rejectionDesc);
      this.txWeight = "Calculating...";
      this.txWeight = "".concat(Number(this.web3.utils.fromWei(String(txWeight.partialFee), "ether")).toFixed(4), " DBIO");
    },
    async calculateDocumentFee() {
      const txWeight = await submitGeneticAnalysisFee(this.api, this.wallet, this.orderDataDetails.geneticAnalysisTrackingId, this.document.recordLink, this.document.description);
      this.txWeight = "Calculating...";
      this.txWeight = "".concat(Number(this.web3.utils.fromWei(String(txWeight.partialFee), "ether")).toFixed(4), " DBIO");
    },
    async handleDownloadFile(link, name) {
      try {
        this.downloading = true;
        let decryptedArrays = [];
        const pair = {
          publicKey: this.orderDataDetails.customerBoxPublicKey,
          secretKey: this.secretKey
        };
        const computeFileName = name ? name : link.split("/").pop();
        if (/^\[/.test(link)) {
          const links = JSON.parse(link);
          let fileType;
          for (let i = 0; i < links.length; i++) {
            const {
              type,
              data
            } = await downloadFile(links[i], true);
            const decryptedFile = decryptFile([data], pair, type);
            fileType = type;
            decryptedArrays = [...decryptedArrays, ...(decryptedFile ? decryptedFile : [])];
          }
          const unit8Arr = new Uint8Array(decryptedArrays);
          await downloadDocumentFile(unit8Arr, computeFileName, fileType);
        } else {
          const {
            type,
            data
          } = await downloadFile(link, true);
          const decryptedFile = decryptFile(data, pair, type);
          await downloadDocumentFile(decryptedFile, computeFileName, type);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.downloading = false;
      }
    },
    async handleSubmitForms() {
      var _this$isDirty;
      this._touchForms("document");
      this._resetForms("document", "description");
      const {
        description: docDescription,
        file: docFile
      } = (_this$isDirty = this.isDirty) === null || _this$isDirty === void 0 ? void 0 : _this$isDirty.document;
      if (docDescription || docFile) return;
      try {
        this.isUploading = true;
        const dataFile = await this.processFile();
        await this.upload({
          encryptedFileChunks: dataFile.chunks,
          fileName: dataFile.fileName,
          fileType: dataFile.fileType,
          fileSize: dataFile.fileSize
        });
        // await submitGeneticAnalysis(
        //   this.api,
        //   this.wallet,
        //   this.orderDataDetails.geneticAnalysisTrackingId,
        //   this.document.recordLink,
        //   this.document.description
        // )

        // TODO: Please use polkadot instead of hardcode
        await this.api.tx.geneticAnalysis.submitGeneticAnalysis(this.orderDataDetails.geneticAnalysisTrackingId, this.document.recordLink, this.document.description).signAndSend(this.wallet, {
          nonce: -1
        });

        // TODO: Please use polkadot instead of hardcode
        await this.api.tx.geneticAnalysis.processGeneticAnalysis(this.orderDataDetails.geneticAnalysisTrackingId, "ResultReady").signAndSend(this.wallet, {
          nonce: -1
        });
        // await processGeneticAnalysis(this.api, this.wallet, this.orderDataDetails.geneticAnalysisTrackingId, "ResultReady")
      } catch (e) {
        this.isUploading = false;
        console.error(e);
      }
    },
    async processFile() {
      const context = this;
      const fr = new FileReader();
      const {
        file
      } = this.document;
      return new Promise((resolve, reject) => {
        fr.onload = async function () {
          try {
            const encrypted = await context.encrypt({
              text: fr.result,
              fileType: file.type,
              fileSize: file.size,
              fileName: file.name
            });
            resolve(encrypted);
          } catch (e) {
            console.error(e);
          }
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(file);
      });
    },
    async encrypt(_ref) {
      let {
        text,
        fileType,
        fileName,
        fileSize
      } = _ref;
      const context = this;
      const arrChunks = [];
      let chunksAmount;
      const pair = {
        secretKey: this.secretKey,
        publicKey: this.orderDataDetails.customerBoxPublicKey
      };
      return await new Promise((resolve, reject) => {
        try {
          cryptWorker.workerEncryptFile.postMessage({
            pair,
            text,
            fileType
          }); // Access this object in e.data in worker
          cryptWorker.workerEncryptFile.onmessage = async event => {
            if (event.data.chunksAmount) {
              chunksAmount = event.data.chunksAmount;
              return;
            }
            arrChunks.push(event.data);
            context.encryptProgress = arrChunks.length / chunksAmount * 100;
            if (arrChunks.length === chunksAmount) {
              resolve({
                fileName,
                fileType,
                fileSize,
                chunks: arrChunks
              });
            }
          };
        } catch (err) {
          reject(new Error(err.message));
        }
      });
    },
    async upload(_ref2) {
      let {
        encryptedFileChunks,
        fileType,
        fileName,
        fileSize
      } = _ref2;
      try {
        this.totalChunks = encryptedFileChunks.length;
        this.currentChunkIndex = 0;
        this.isFailed = false; // Reset isFailed before starting the upload

        for (let i = 0; i < this.totalChunks; i++) {
          let data = ["{\"seed\":".concat(encryptedFileChunks[i].seed, ",\"data\":{\"nonce\":[").concat(encryptedFileChunks[i].data.nonce, "],\"box\":[").concat(encryptedFileChunks[i].data.box, "]}}")];
          const blob = new Blob(data, {
            type: fileType
          });
          console.log("data to be uploaded is");
          console.log(blob);
          try {
            const result = await uploadFile({
              title: fileName,
              type: fileType,
              size: fileSize,
              file: blob
            });
            const link = await getFileUrl(result.IpfsHash);
            this.documentLinks.push(link);
          } catch (error) {
            console.error("Error on chunk upload", error);
            this.isFailed = true; // Set isFailed to true if the upload fails for any chunk
          }

          this.currentChunkIndex++; // Increment the currentChunkIndex regardless of success or failure
        }

        this.document.recordLink = JSON.stringify(this.documentLinks);
      } catch (e) {
        console.error("Error on upload", e);
      }
    },
    handleShowTooltip(e) {
      if (e.type === "mouseenter") {
        this.showTooltip = true;
      } else {
        this.showTooltip = false;
      }
    },
    formatBytes(bytes) {
      let decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
      if (bytes === 0 || !bytes) return "(0 Bytes)";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const indexSizes = Math.floor(Math.log(bytes) / Math.log(k));
      const computeValue = parseFloat((bytes / Math.pow(k, indexSizes)).toFixed(dm));
      return "(".concat(computeValue, " ").concat(sizes[indexSizes], ")");
    }
  }
};